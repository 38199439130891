import React, { useEffect } from 'react';

import LoginBlockApple from './login_block_apple';
import LoginBlockFacebook from './login_block_facebook';
import LoginBlockGoogle from './login_block_google';
import {useTranslation} from "react-i18next";

const LoginBlockSocial = ({ config }) => {

    const { t } = useTranslation();

    const divStyle = {
        width: "100%",
    };



    return (
        <div style={divStyle}>

            <div className="login_block_social_text_holder">
                <div className="login_block_social_text_side"></div>
                 <div className="login_block_social_text bold">{t("login_block_social_text")}</div>
                <div className="login_block_social_text_side"></div>
            </div>

            <div className="login_block_social">
                <div className="login_block_social_buttons">
                    <LoginBlockFacebook config={config} />
                    <LoginBlockGoogle config={config} />
                </div>

            </div>
        </div>
    );
};

export default LoginBlockSocial;
